import { useEffect } from 'react';
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import { useRouter } from 'next/router';
import { msalInstance } from '../authConfig';
import { InteractionStatus } from '@azure/msal-browser';
import { Flex, Spinner } from '@chakra-ui/react';

/**
 * Parameter to wait for before redirecting to home page after login.
 * This is used to prevent the login screen from briefly flashing before
 * redirecting to the home page.
 */
const WAITING_TIME_BEFORE_REDIRECT = 100;

/**
 * Renders child components if user is authenticated and redirects to home page
 * if not.
 *
 * Use this component to wrap pages that should only be accessible to
 * authenticated users. Do not implement logic inside the component itself.
 * The proper way to wrap secured pages in order to prevent 401 responses from the∫
 * server during the msal authentication process is the following:
 * ```tsx
 * function _PageToBeSecured(){
 *  const { data } = useQuery({
 *    queryKey: ["key"],
 *    queryFn: () => getSomething(),
 *    enabled: true,
 *  });
 *  return (<div>Secured content</div>);
 * }
 *
 * function PageToBeSecured(){
 *  return (
 *   <SecuredPage>
 *    <_PageToBeSecured />
 *    </SecuredPage>
 *  );
 * }
 *
 * export default PageToBeSecured;
 * ```
 */

export default function SecuredPage({ children }) {
  const { inProgress } = useMsal();
  const router = useRouter();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      console.log('isAuthenticated', isAuthenticated, 'inProgress', inProgress);
      if (isAuthenticated == false && inProgress == InteractionStatus.None) {
        router.replace('/');
      }
    }, WAITING_TIME_BEFORE_REDIRECT);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isAuthenticated, inProgress]);

  return (
    <MsalProvider instance={msalInstance}>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Flex
          height="100vh"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner />
        </Flex>
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
}
