import { Flex } from '@chakra-ui/react';
import Header from './Header';
import Body from './Body';

const Content = () => {
  return (
    <Flex
      minHeight={{ base: '100vh', xl: '760px' }}
      maxHeight={{ xl: '760px' }}
      direction="column"
      alignItems="center"
      justifyContent="center"
      background="#ffffff"
      paddingBottom={{ base: 5, lg: 0 }}
      width="100%"
      maxWidth={{ xl: '767px' }}
      flex={1}
      borderLeftRadius={{ xl: 12 }}
      bgImage="url('/static/images/starting/startingInfoBg.svg')"
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
    >
      <Header />
      <Body />
    </Flex>
  );
};

export default Content;
