import Image from 'next/image';
import { Flex, Box, Text } from '@chakra-ui/react';
import { LoginButton } from '@features/Auth/components';
import { useTranslation } from 'next-i18next';
import { UnauthenticatedTemplate } from '@azure/msal-react';

const Body = () => {
  const { t: translate } = useTranslation(['starting']);
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
    >
      <Box mt={{ base: 6, md: 4 }}>
        <Image
          src="/static/images/starting/starting-image.png"
          alt="Starting image"
          width={260}
          height={265}
        />
      </Box>
      <Text
        width={230}
        textAlign="center"
        my={5}
        color="gray.600"
      >
        {translate(`Meet awesome people & grow together`)}
      </Text>
      <Flex
        flexDirection="column"
        alignItems="center"
        gap={4}
        mt={{ base: 6, lg: 16 }}
      >
        <UnauthenticatedTemplate>
          <LoginButton />
        </UnauthenticatedTemplate>
      </Flex>
    </Flex>
  );
};

export default Body;
