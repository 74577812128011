import { Image } from '@chakra-ui/react';
import dynamic from 'next/dynamic';
import { ComponentType, useContext } from 'react';
import { startingLogosPath } from '@shared/helpers/config';
import { useTranslation } from 'next-i18next';
import { AuthContext } from '../context';

type Props = {
  bg: string;
  color: string;
  width: string;
  maxWidth: string;
  minWidth: string;
  onClick: () => void;
  children: any;
  borderRadius: String;
  px: String;
  py: String;
};

const HuggaButton: ComponentType<Props> = dynamic(() =>
  import('hugga-reactjs-front-library').then(({ HuggaButton: Button }) => Button)
);

const LoginButton = () => {
  const { logIn } = useContext(AuthContext);
  const { t } = useTranslation('starting');
  const buttonText = t('Start with Google');
  const buttonLogo = `${startingLogosPath}/google-logo.png`;

  return (
    <HuggaButton
      bg="linear-gradient(132.66deg, #FFFFFF -55.59%, #F9F8FA 221.41%)"
      color="black"
      width="calc(100vw - 72px)"
      maxWidth="360px"
      minWidth="220px"
      onClick={logIn}
      borderRadius="99px"
      px="59.5px"
      py="10px"
    >
      <Image
        src={buttonLogo}
        alt="google"
        width="16px"
        mr={3}
      />
      {buttonText}
    </HuggaButton>
  );
};

export default LoginButton;
