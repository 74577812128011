import { Flex } from '@chakra-ui/react';
import { Content } from '@features/Welcome/components';
import { useState } from 'react';
import { FullscreenLoader } from '@shared/components/FullscreenLoader/FullscreenLoader';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { NextPageWithLayout } from './_app';
import Layout from '../components/Layout';
import Head from 'next/head';
import { DEFAULT_NAMESPACES } from '@src/constants';

const Welcome: NextPageWithLayout = () => {
  const [loading] = useState(false);

  return (
    <Layout>
      <Head>
        <title>Hügga</title>
      </Head>
      {loading ? (
        <FullscreenLoader />
      ) : (
        <Flex
          // width="100vw"
          alignItems="center"
          justifyContent="center"
          minHeight="calc(100vh - 56px)"
          backgroundColor="gray.50"
        >
          <Flex
            width="100vw"
            maxWidth="1366px"
            maxHeight={{ xl: '200rem' }}
            justifyContent={{ base: 'center', lg: 'flex-start' }}
            alignItems="center"
            borderRadius={12}
            boxShadow="0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0,0,0,0.2)"
            background="gray.200"
          >
            <Content />
          </Flex>
        </Flex>
      )}
    </Layout>
  );
};

export const getStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, [...DEFAULT_NAMESPACES, 'starting'])),
    },
  };
};

export default Welcome;
