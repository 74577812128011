import Image from 'next/image';
import { Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { huggaLogoPath } from '../../../shared/helpers/config';
import { useTranslation } from 'next-i18next';

const Header = () => {
  const [isLargerThan767] = useMediaQuery('(min-width: 767px)');
  const { t: translate } = useTranslation(['starting']);
  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Image
        src={huggaLogoPath}
        alt="logo"
        width={100}
        height={36}
      />
      <Text
        mt={isLargerThan767 ? 8 : 5}
        mb={isLargerThan767 && 8}
        fontSize="lg"
        textAlign="center"
        color="gray.700"
      >
        {translate("Let's build your profile")}
      </Text>
    </Flex>
  );
};

export default Header;
